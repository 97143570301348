<template>
    <div class="services" id="services">
        <div class="title">
            <h2 style="color: #1C1C1E">Услуги</h2>
        </div>

        <div class="cards">
            <div class="service-card" @click="showService(1)">
                <img src="../../assets/img/services/tools.svg" alt="" class="card-icon">

                <p>Сантехнические услуги</p>
            </div>

            <div class="service-card" @click="showService(2)">            
                <img src="../../assets/img/services/pipe.svg" alt="" class="card-icon">

                <p>Водоснабжение</p>
            </div>

            <div class="service-card" @click="showService(3)">
                <img src="../../assets/img/services/fan.svg" alt="" class="card-icon">

                <p>Вентиляция</p>
            </div>

            <div class="service-card" @click="showService(4)">
                <img src="../../assets/img/services//sewage.svg" alt="" class="card-icon">

                <p>Канализация</p>
            </div>

            <div class="service-card" @click="showService(5)">
                <img src="../../assets/img/services/welding.svg" alt="" class="card-icon">

                <p>Сварочные работы</p>
            </div>

            <div class="service-card" @click="showService(6)">
                <img src="../../assets/img/services/heat.svg" alt="" class="card-icon">

                <p>Отопление</p>
            </div>
        </div>

        <ModalWindow :isOpen="isModalOpen" :isServices="isServices" :id="id" @close="isServices = false;
        isModalOpen = false"/>

        <p class="p-bottom">Нажмите на карточку для раскрытия списка услуг</p>
    </div>
</template>

<script>
import ModalWindow from './ModalWindow.vue';

export default {
    name: "ServicesPart",
    data() {
        return {
            isModalOpen: false,
            isServices: false,
            id: 0
        };
    },
    components: { ModalWindow },
    methods: {
        showService(id) {
            this.isModalOpen = true;
            this.isServices = true;
            this.id = id;
        }
    }
}
</script>

<style lang="sass">
.services
    background-color: #F2F2F7

.title
    display: flex
    flex-direction: column
    text-align: center
    padding: 16px 0
    font-family: 'Noto Sans', sans-serif
    font-size: 2rem

h2
    font-family: 'Noto Sans', sans-serif
    margin: 0
    font-weight: 400
    font-size: 32px
    text-align: center

.cards
    display: flex
    justify-content: space-evenly
    flex-wrap: wrap
    column-gap: 32px
    row-gap: 48px
    padding: 48px 32px 32px 32px

.service-card   
    width: 200px
    row-gap: 48px
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 20px
    padding: 32px
    background-color: rgba(255, 255, 255, 0.75)
    justify-content: space-around
    cursor: pointer
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
    &:active, .service-card:focus 
        transform: scale(0.9)
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1)

.card-icon
    width: 168px

.service-card-arrow
    width: 32px

.upper-window
    position: fixed
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    color: #F2F2F7

.list-of-services
    max-width: 1305px
    max-height: 70vh
    background-color: rgb(256 256 256 / 10%)
    margin-bottom: 32px
    margin-right: 0
    backdrop-filter: blur(5px)
    border-radius: 4px
    color: #fff
    overflow-y: auto
    &::-webkit-scrollbar
        width: 16px
    &::-webkit-scrollbar-track
        background: #F2F2F7
    &::-webkit-scrollbar-thumb
        background-color: #cfcfd4
        border-radius: 1rem
        border: 4px solid #F2F2F7
    &::-webkit-scrollbar-button 
        display:none

.title-full-card
    display: flex
    flex-direction: column
    text-align: center
    padding-top: 16px
    font-family: 'Noto Sans', sans-serif
    font-size: 32px
    margin-bottom: 32px

.p-bottom
    padding: 0 16px 16px 16px
</style>
