<template>
    <div id="additional" class="additional">
        <div class="title">
            <h2>Дополнительно</h2>
        </div>

        <div class="cards">
            <div class="additional-card">
                <img src="../../assets/img/additionally/clock.svg" alt="" class="card-icon">

                <p>Опыт более 10 лет</p>
            </div>

            <div class="additional-card">
                <img src="../../assets//img//additionally/house.svg" alt="" class="card-icon">

                <p>Без проживания на объекте</p>
            </div>
            
            <div class="additional-card">
                <img src="../../assets/img/additionally/people.svg" alt="" class="card-icon">

                <p>Бригада в наличии</p>
            </div>

            <div class="additional-card">
                <img src="../../assets/img/additionally/saphire.svg" alt="" class="card-icon">

                <p>Хорошее качество</p>
            </div>

            <div class="additional-card">
                <img src="../../assets/img/additionally/agreement.svg" alt="" class="card-icon">

                <p>Работа по договору</p>
            </div>

            <div class="additional-card">
                <img src="../../assets/img/additionally/guarantee.svg" alt="" class="card-icon">

                <p>Гарантия</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BasementPart'
}
</script>

<style lang="sass">
.additional-card
    width: 200px
    background-color: rgba(255, 255, 255, 0.75)
    display: flex
    row-gap: 32px
    flex-direction: column
    align-items: center
    border-radius: 20px
    padding: 32px
    justify-content: space-around
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
</style>
