<template>
    <div class="main" id="main">
        <div class="menu">
            <div class="icon-list">
                <A class="icon-item" href="#main">
                    <img src="../assets/img/other/house.svg" alt="Services Icon">
                    <span class="icon-text">Главная</span>
                </A>

                <A class="icon-item" href="#services">
                    <img src="../assets/img/other/tool-box.svg" alt="Photos Icon">
                    <span class="icon-text">Услуги</span>
                </A>

                <A class="icon-item" href="#photos">
                    <img src="../assets/img/other/picture.svg" alt="Photos Icon">
                    <span class="icon-text">Фотографии</span>
                </A>

                <A class="icon-item" href="#reviews">
                    <img src="../assets/img/other/star.svg" alt="Reviews Icon">
                    <span class="icon-text">Отзывы</span>
                </A>

                <A class="icon-item" href="#additional">
                    <img src="../assets/img/other/plus.svg" alt="Additional Icon">
                    <span class="icon-text">Дополнительно</span>
                </A>

                <A class="icon-item" href="#contact">
                    <img src="../assets/img/other/phone.svg" alt="Contact Icon">
                    <span class="icon-text">Контакты</span>
                </A>
            </div>
        </div>

        <div class="introduction-background">
            <div class="introduction" id="introduction">
                <p></p>
            
                <h1>Монтаж и ремонт отопления, водоснабжения, канализации и вентиляции</h1>
            
                <a href="#services" class="blinking-link">
                    <img src="@/assets/img/other/arrow.svg" alt="стрелка, указывающая вниз" class="arrow-downward">
                </a>
            </div>
        </div>
    </div>

    <div class="parts" @click="showModale = true">
        <ServicesPart></ServicesPart>
        <ExamplesPart></ExamplesPart>
        <ReviewsPart></ReviewsPart>
        <AdditionalPart></AdditionalPart>
        <ContactPart></ContactPart>
        <BasementPart></BasementPart>
    </div>
</template>

<script>
import ServicesPart from './Parts/ServicesPart.vue';
import ExamplesPart from './Parts/ExamplesPart.vue';
import ReviewsPart from './Parts/ReviewsPart.vue';
import AdditionalPart from './Parts/AdditionalParts.vue';
import ContactPart from './Parts/ContactPart.vue'
import BasementPart from './Parts/BasementPart.vue'

export default {
    name: "AppContent",
    components: {
        ServicesPart,
        ExamplesPart,
        ReviewsPart,
        AdditionalPart,
        ContactPart,
        BasementPart
    }
}
</script>

<style lang="sass">
.main
    height: 100vh

.menu
    position: fixed
    top: 10px
    display: flex
    justify-content: center
    left: calc( 50% - 176px )
    max-width: 2560px
    z-index: 1

.introduction
    width: 100% 
    height: 100vh   
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: column
    background-color: rgb(0, 0, 0, 0.3)

.introduction-background
    background-image: url("@/assets/img/other/background.jpg")
    background-size: cover
    background-position: center bottom
    animation: moveBackground 60s linear infinite

@keyframes moveBackground
    100%
        background-position-y: 100vh

.upper-panel
    display: flex
    flex-direction: row
    align-items: center
    column-gap: 48Px
    margin-top: 16px
    justify-content: space-around
    min-width: 12rem
    width: 100%

.number
    font-family: 'Noto Sans', sans-serif
    font-size: 24px
    color: #F2F2F7
    padding-left: 16px
    font-weight: 500

.navigation
    font-size: 1.5rem

.menu-icon
    width: 25px
    display: none
    transition: transform 200ms
    &:active
        transform: scale(0.8)

a
    color: #2c3e50
    text-decoration: none
    -webkit-tap-highlight-color: transparent

ul
    font-family: 'Noto Sans', sans-serif
    list-style-type: none
    margin: 0
    padding: 0

.ul-menu
    display: flex
    flex-direction: row
    column-gap: 48px

p
    font-family: 'Noto Sans', sans-serif
    margin: 0
    font-weight: 400
    font-size: 16px
    text-align: center

h1
    font-family: 'Noto Sans', sans-serif
    width: 450px
    margin: 0
    color: #F2F2F7
    font-weight: 500
    text-align: center
    padding: 32px

.arrow-downward
    width: 32px
    padding: 16px
    margin-bottom: 80px
    transition: transform 200ms
    &:active
        transform: scale(0.8)

.blinking-link
  animation: blink-animation 2s infinite

@keyframes blink-animation 
  0% 
    opacity: 1
  50% 
    opacity: 30%
  100%
    opacity: 1

.icon-list 
    display: flex
    padding: 16px 24px
    column-gap: 32px
    background-color: rgba(256, 256, 256, 0.2)
    backdrop-filter: blur(10px)
    -webkit-backdrop-filter: blur(20px)
    border-radius: 32px

.icon-item 
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    cursor: pointer
    transition: transform 0.3s ease-in-out

.icon-text 
    font-family: 'Noto Sans', sans-serif
    display: none
    position: absolute
    top: calc(100% + 20px)
    left: 50%
    transform: translateX(-50%)
    color: white
    padding: 8px
    border-radius: 10px
    white-space: nowrap
    background-color: rgba(0, 0, 0, 0.4)
    backdrop-filter: blur(10px)
    -webkit-backdrop-filter: blur(20px)

.icon-item:hover .icon-text 
    display: block

.icon-item img
    width: 24px
    transition: transform 0.3s ease-in-out

.icon-item img:hover 
    transform: scale(1.6)

.icon-item img:active, .icon-item img:focus 
    transform: scale(0.8)
    transition: transform 0.3s cubic-bezier(0.6, 0.2, 0.1, 1)
    animation: none

.service-card, .reviews-card, .additional-card, .contact-card
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2)
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.03)

.photo
    -webkit-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.2)
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.1)

.icon-list, .button, .contact-buttons
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.15)
    cursor: pointer

.modale
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center

@media (max-width: 1807px)
    .cards
        row-gap: 32px
    .service-card
        width: 100px
        row-gap: 32px
    .card-icon
        width: 100px
    .photo
        height: 210px
    .additional-card
        width: 100px
        row-gap: 16px

@media (max-width: 1207px)
    .service-card
        width: 200px
        row-gap: 48px
    .card-icon
        width: 168px
    .photo
        height: 340px
    .additional-card
        width: 200px
        row-gap: 48px
    .contact-card
        width: 600px

@media (max-width: 770px)
    .list
        flex-wrap: wrap

@media (max-width: 750px)
    .a-hide-full-buttons
        display: none
    .mini-buttons
        display: block
    .a-button
        display: block
    .contact-card
        width: 200px
    .contact-upper-part
        flex-wrap: wrap
    .additional-card
        width: 100px
        row-gap: 16px

@media (max-width: 623px)
    .cards
        column-gap: 48px
        row-gap: 16px
    .service-card
        width: 100px
        row-gap: 32px
    .card-icon
        width: 100px
    .photo
        height: 210px

@media (max-width: 623px)
    .examples-buttons
        column-gap: 16px

@media (max-width: 583px)
    .menu
        left: calc( 50% - 146px )
    .icon-list
        column-gap: 20px
    h1
        font-size: 24px
        width: 330px
    h2
        font-size: 24px
    .contact-upper-part
        flex-direction: column
    .contact-bottom-part
        align-items: center
    .basement
        column-gap: 32px

@media (max-width: 440px)
    .cards
        column-gap: 16px
        padding: 32px 16px 16px 16px
</style>