<template>
    <div class="modal-overlay" v-show="isOpen">
        <div class="background" @click="closeModal()">
        </div>

        <div class="modal-card" v-if="isServices">
            <div v-if="id == 1">
                <div class="card-title">
                    <p></p>

                    <h2 style="color: #1C1C1E">Сантехнические услуги</h2>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <div class="list">
                        <ol>
                            <li>Монтаж сантехнического оборудования</li>
                            <li>Замена водосчетчика</li>
                            <li>Монтаж сантехнических и отопительных труб</li>
                            <li>Установка радиатора отопления</li>
                            <li>Монтаж душевой кабины</li>
                            <li>Установка и подключение стиральной машины</li>
                            <li>Монтаж унитаза</li>
                            <li>Монтаж унитаза с инсталляцией</li>
                            <li>Установка водосчетчика</li>
                            <li>Установка и подключение посудомоечной машины</li>
                            <li>Монтаж ванны</li>
                            <li>Демонтаж сантехники</li>
                            <li>Замена смесителя</li>
                            <li>Замена унитаза</li>
                            <li>Монтаж смесителя</li>
                            <li>Монтаж полотенцесушителя</li>
                            <li>Монтаж теплового пола</li>
                            <li>Установка водонагревателя</li>
                            <li>Демонтаж счетчиков воды и тепла</li>
                            <li>Монтаж фильтра очистки воды</li>
                        </ol>
            
                        <ol start="21">
                            <li>Установка электрокотла</li>
                            <li>Монтаж кухонной мойки</li>
                            <li>Монтаж акрилловой ванны</li>
                            <li>Монтаж подиума под душевую кабину</li>
                            <li>Монтаж экрана в ванной</li>
                            <li>Монтаж труб отопления</li>
                            <li>Демонтаж ванны</li>
                            <li>Монтаж раковины</li>
                            <li>Устранение протечки</li>
                            <li>Подвод к канализационной сети</li>
                            <li>Установка теплового насоса</li>
                            <li>Монтаж сантехнического шкафа</li>
                            <li>Монтаж водосчетчика</li>
                            <li>Монтаж умывальника</li>
                            <li>Демонтаж унитаза</li>
                            <li>Монтаж счетчика отопления</li>
                            <li>Монтаж чугунной ванны</li>
                            <li>Монтаж сантехнического люка</li>
                            <li>Установка душа</li>
                            <li>Установка и подключение измельчителя отходов</li>
                        </ol>

                        <ol start="41">
                            <li>Монтаж сифона</li>
                            <li>Перенос полотенцесушителя</li>
                            <li>Подвод к водопроводной сети</li>
                            <li>Установка шарового крана</li>
                            <li>Замена раковины</li>
                            <li>Монтаж экрана на радиатор отопления</li>
                            <li>Монтаж джакузи</li>
                            <li>Монтаж чугунной ванны</li>
                            <li>Монтаж короба на радиатор отопления</li>
                            <li>Монтаж писсуара</li>
                            <li>Монтаж подиума под ванну</li>
                            <li>Демонтаж раковины</li>
                            <li>Монтаж биде</li>
                        </ol>
                    </div>
            </div>

            <div v-if="id == 2">
                <div class="card-title">
                    <p></p>

                    <h2 style="color: #1C1C1E">Водоснабжение</h2>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <ol>
                    <li>Прокладка труб водоснабжения</li>
                    <li>Монтаж газового котла</li>
                    <li>Установка водосчетчика</li>
                    <li>Установка фильтра очистки воды</li>
                    <li>Очистка систем отопления и водоснабжения</li>
                    <li>Монтаж радиатора</li>
                    <li>Монтаж станции биологической очистки</li>
                    <li>Монтаж систем подачи воды</li>
                    <li>Монтаж водонагревателя</li>
                    <li>Установка счетчика отопления</li>
                    <li>Монтаж дополнительных систем очистки воды</li>
                    <li>Монтаж водораспределительного оборудования</li>
                    <li>Демонтаж сетей отопления и водоснабжения</li>
                    <li>Монтаж теплового пункта</li>
                    <li>Демонтаж радиатора</li>
                    <li>Гидроизоляция труб водопровода</li>
                    <li>Монтаж измерительного оборудования для воды и тепла</li>
                    <li>Установка теплосчетчика</li>
                    <li>Замена счетчика отопления</li>
                </ol>
            </div>

            <div v-if="id == 3">
                <div class="card-title">
                    <p></p>

                    <h2 style="color: #1C1C1E">Вентиляция</h2>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <ol>
                    <li>Монтаж вен каналов (пластик)</li>
                    <li>Монтаж вен каналов (металл)</li>
                    <li>Фановая вентиляция</li>
                    <li>Установка аэраторов</li>
                    <li>Принудительная вентиляция</li>
                    <li>Установка электрических вентиляторов</li>
                    <li>Монтаж решеток</li>
                </ol>
            </div>

            <div v-if="id == 4">
                <div class="card-title">
                    <p></p>

                    <h2 style="color: #1C1C1E">Канализация</h2>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <ol>
                    <li>Прокладка канализационных труб</li>
                    <li>Чистка канализационных систем</li>
                    <li>Монтаж наружной канализации</li>
                    <li>Монтаж дренажной системы</li>
                    <li>Монтаж ливневой канализации</li>
                    <li>Демонтаж септика</li>
                    <li>Гидроизоляция труб канализации и водопровода</li>
                    <li>Демонтаж канализационной сети</li>
                    <li>Замена фильтра септика</li>
                </ol>
            </div>

            <div v-if="id == 5">
                <div class="card-title">
                    <p></p>

                    <h2 style="color: #1C1C1E">Сварочные работы</h2>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <ol>
                    <li>Электрическая дуговая сварка</li>
                </ol>
            </div>

            <div v-if="id == 6">
                <div class="card-title">
                    <p></p>

                    <h2 style="color: #1C1C1E">Отопление</h2>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <ol>
                    <li>Монтаж отопления</li>
                    <li>Монтаж радиаторов</li>
                    <li>Монтаж конвектора</li>
                    <li>Монтаж тепловых завес</li>
                    <li>Монтаж теплого пола</li>
                    <li>Обьвязка котельной</li>
                    <li>Монтаж электрического котла</li>
                    <li>Монтаж газового котла</li>
                    <li>Монтаж дровяного котла</li>
                    <li>Монтаж пилетного котла</li>
                    <li>Монтаж распределительных коллекторов</li>
                    <li>Монтаж трёхходовой клапан</li>
                    <li>Установка бойлера косвенного нагрева</li>
                </ol>
            </div>
        </div>

        <div class="modal-card" style="padding: 0;" v-if="isPhoto">
            <div v-if="id == 1" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>*Нажмите* для закрытия карточки</p>

                    <img src="@/assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="@/assets/img/examples/1.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 2" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="@/assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="@/assets/img/examples/2.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 3" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/3.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 4" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/4.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 5" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/5.jpeg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 6" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/6.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 7" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/7.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 8" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/8.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 9" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/9.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 10" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/10.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 11" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/11.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 12" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/12.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 13" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/13.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 14" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/14.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 15" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/15.jpg" alt="" class="expanded-photo">
            </div>  

            <div v-if="id == 16" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/16.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 17" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/17.jpg" alt="" class="expanded-photo">
            </div>

            <div v-if="id == 18" class="photo-card">
                <div class="card-title">
                    <p></p>

                    <p>"Нажмите" для закрытия карточки</p>

                    <img src="../../assets/img/buttons/close.svg" alt="" @click="closeModal()">
                </div>

                <img src="../../assets/img/examples/18.jpg" alt="" class="expanded-photo">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalWindow",
    props: {
        isOpen: {
            type: Boolean
        }, isServices: {
            type: Boolean
        }, id: {
            type: Number
        }, isPhoto: {
            type: Boolean
        }
    },
    data() {
        return {
            isFirstList: false,
            isSecondList: false
        };
    },
    watch: {
    isOpen: function (newValue) {
        if (newValue) {
            this.disableScroll();
        } else {
            this.enableScroll();
        }
    }
    },
    methods: {
        disableScroll() {
            document.body.classList.add("modal-open");
        }, enableScroll() {
            document.body.classList.remove("modal-open");
        }, closeModal() {
            this.$emit('close');
        }
    }
};
</script>

<style lang="sass">
.modal-overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 9999
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    row-gap: 8px
    opacity: 0
    animation: fade-in-second 0.2s ease-in forwards

@keyframes fade-in-second
    from
        opacity: 0
    to
        opacity: 1

.background
    position: fixed
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.25)
    z-index: -1
    cursor: pointer

body.modal-open
    overflow: hidden

.modal-card
    padding: 16px
    margin: 32px
    background-color: rgba(255, 255, 255, 0.9)
    border-radius: 20px
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.2)
    overflow: auto
    color: #000
    animation: fade-in-modal-card 0.1s ease-in forwards

@keyframes fade-in-modal-card
    from
        transform: scale(0.8)

.close
    padding: 16px
    display: flex
    justify-content: center
    align-items: center
    column-gap: 16px
    color: #000
    background-color: rgba(255, 255, 255, 0.5)
    backdrop-filter: blur(20px)
    border-radius: 30px
    cursor: pointer
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2)
    box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.2)

.card-title
    display: flex
    align-items: center
    justify-content: space-between
    column-gap: 32px
    padding: 16px

.card-title img
    width: 24px
    cursor: pointer

.card-title p
    min-width: 16px

.photo-card
    display: flex
    flex-direction: column
    align-items: center

.list
    display: flex
    column-gap: 16px
    align-items: flex-start

ol
    margin: 0

li
    font-size: 16px
    font-family: 'Noto Sans', sans-serif
    color: #000

.expanded-photo
    max-height: 90vh
    max-width: 95vw
    border-radius: 0 0 10px 10px

.card-body
    margin-top: 16px

.card-body p
    font-family: 'Noto Sans', sans-serif
    text-align: left

.list-license
    display: flex
    align-items: center
    cursor: pointer

.list-license img
    width: 24px

.license
    font-family: 'Noto Sans', sans-serif
    margin: 16px 0
</style>