<template>
    <div class="reviews" id="reviews">
        <div class="title">
            <h2 style="color: #1C1C1E">Отзывы</h2>
        </div>

        <div class="cards" style="align-items: center">
            <div class="reviews-card">
                <p class="p-card">
                    Игорю огромное спасибо, выполнил все работы по установке водопровода и канализации качественно и эстетично.
                </p>

                <p class="p-card">06.02.2022</p>
            </div>

            <div class="reviews-card">
                <p class="p-card">
                    Игорь Евгеньевич очень высококвалиф. мастер! К своему делу относится очень ответственно, также очень радует
                    в его работе соотношение цена-качество.
                </p>

                <p class="p-card">19.02.2021</p>
            </div>

            <div class="reviews-card" v-if="is1CardExpanded == false">
                <p class="p-card">
                    Выражаю огромную благодарность Игорю Евгеньевичу и его команде за выполненную работу по проведению летнего водопровода из
                    труб ПНД в садоводческом товариществе "Часовщик-1" г. Углич.
                    <a style="color: #0000EE;" @click="is1CardExpanded = true">Еще...</a>
                </p>

                <p class="p-card">13.06.2022</p>
            </div>

            <div class="reviews-card" v-if="is1CardExpanded">
                <p class="p-card">
                    Выражаю огромную благодарность Игорю Евгеньевичу и его команде за выполненную работу по проведению летнего водопровода из труб ПНД в садоводческом товариществе "Часовщик-1" г. Углич. Быстро определили объем работ, сроки, помогли с закупкой и доставкой материалов.
                    Цены, оперативность, качество и профессионализм на высшем уровне. Все хорошо. Спасибо!
                    Могу с уверенностью рекомендовать данного исполнителя.
                </p>

                <p class="p-card">13.06.2022</p>
            </div>

            <div class="reviews-card" v-if="is2CardExpanded == false">
                <p class="p-card">
                    Игорь Евгеньевич очень высококвалиф. мастер! К своему делу относится очень ответственно, также очень радует
                    в его работе соотношение цена-качество.
                    <a style="color: #0000EE;" @click="is2CardExpanded = true">Еще...</a>
                </p>

                <p class="p-card">19.02.2021</p>
            </div>

            <div class="reviews-card" v-if="is2CardExpanded">
                <p class="p-card">
                    Игорь Евгеньевич очень высококвалифицированный мастер! К своему делу относится очень ответственно,
                    также очень радует в его работе соотношение цена-качество. Во время нашего с ним сотрудничества
                    были выполнены ремонт офиса; внутренняя и наружная отделки жилых помещений, все сделано чисто,
                    аккуратно, как «для себя». Очень благодарна Игорю Евгеньевичу за сэкономленное время и финансы!
                </p>

                <p class="p-card">19.02.2021</p>
            </div>

            <div class="reviews-card">
                <p class="p-card">
                    Отличный специалист, знает своё дело. Делает всё качественно и в срок. Обращаюсь не первый раз. Всем советую.
                </p>

                <p class="p-card">19.07.2022</p>
            </div>
        </div>

        <p class="p-bottom" style="color: #1C1C1E;">Отзывы взяты со страницы на <a href="https://uslugi.yandex.ru/profile/IgorEvgenevichK-532182" style="color: #0000EE;">Яндекс Услугах</a></p>
    </div>
</template>

<script>
export default {
    name: 'ReviewsPart',
    data() {
        return {
            is1CardExpanded: false,
            is2CardExpanded: false
        };
    }
}
</script>

<style lang="sass">
.reviews
    background-color: #F2F2F7
    
.reviews-card
    background-color: rgba(255, 255, 255, 0.75)
    display: flex
    flex-direction: column
    align-items: center
    text-align: right
    padding: 16px
    border-radius: 10px
    padding: 32px
    width: 320px
    row-gap: 16px
    transition: transform 200ms
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)

.p-card a
    cursor: pointer
</style>
