<template>
    <div class="basement">
        <div class="basement-block">
            <a href="http://миркушин.рф/">Разработчик </a>
        </div>

        <div class="basement-block" v-if="isMailShowed == false" @click="isMailShowed = true">
            <a href="mailto:perebos7@yandex.ru">Почта</a>
        </div>

        <div class="basement-block" v-if="isMailShowed">
            <a href="mailto:perebos7@yandex.ru">Perebos7@yandex.ru</a>
        </div>
    </div>

    <ModalWindow :isOpen="isModalOpen" @close="isModalOpen = false; isLicense = false"/>
</template>

<script>
import ModalWindow from './ModalWindow.vue';

export default {
    name: 'BasementPart',
    data() {
        return {
            isModalOpen: false,
            isMailShowed: false
        };
    },
    components: { ModalWindow }
}
</script>

<style lang="sass">
.basement
    background-color: rgba(255, 255, 255, 0.9)
    padding-top: 16px
    padding-bottom: 16px
    display: flex
    justify-content: center
    column-gap: 64px
    padding: 16px 8px
    flex-wrap: wrap
    row-gap: 32px

.basement-block
    display: flex
    align-items: center

.basement-block button
    margin-left: 8px

a
    font-family: 'Noto Sans', sans-serif
</style>
