<template>
    <div id="photos" class="photos">
        <div class="title">
            <h2>Фотографии</h2>
        </div>

        <div class="cards">
            <img src="../../assets//img/examples/1.jpg" alt="" class="photo" @click="showPhoto(1)">

            <img src="../../assets//img/examples/2.jpg" alt="" class="photo" @click="showPhoto(2)">   

            <img src="../../assets//img/examples/3.jpg" alt="" class="photo" @click="showPhoto(3)">

            <img src="../../assets//img/examples/4.jpg" alt="" class="photo" @click="showPhoto(4)">

            <img src="../../assets//img/examples/5.jpeg" alt="" class="photo" @click="showPhoto(5)">

            <img src="../../assets//img/examples/6.jpg" alt="" class="photo" @click="showPhoto(6)">
        </div>

        <div class="cards" v-if="isMorePhoto">
            <img src="../../assets//img/examples/7.jpg" alt="" class="photo" @click="showPhoto(7)">

            <img src="../../assets//img/examples/8.jpg" alt="" class="photo" @click="showPhoto(8)">   

            <img src="../../assets//img/examples/9.jpg" alt="" class="photo" @click="showPhoto(9)">

            <img src="../../assets//img/examples/10.jpg" alt="" class="photo" @click="showPhoto(10)">

            <img src="../../assets//img/examples/11.jpg" alt="" class="photo" @click="showPhoto(11)">

            <img src="../../assets//img/examples/12.jpg" alt="" class="photo" @click="showPhoto(12)">
        </div>

        <div class="cards" v-if="isMoreMorePhoto">
            <img src="../../assets//img/examples/13.jpg" alt="" class="photo" @click="showPhoto(13)">

            <img src="../../assets//img/examples/14.jpg" alt="" class="photo" @click="showPhoto(14)">   

            <img src="../../assets//img/examples/15.jpg" alt="" class="photo" @click="showPhoto(15)">

            <img src="../../assets//img/examples/16.jpg" alt="" class="photo" @click="showPhoto(16)">

            <img src="../../assets//img/examples/17.jpg" alt="" class="photo" @click="showPhoto(17)">

            <img src="../../assets//img/examples/18.jpg" alt="" class="photo" @click="showPhoto(18)">
        </div>

        <ModalWindow :isOpen="isModalOpen" :isPhoto="isPhoto" :id="id" @close="isServices = false; isModalOpen = false"/>

        <div class="examples-buttons">
            <p style="color: #1C1C1E">Нажмите на фото для увеличения изображения</p>

            <div class="button" v-if="MorePhotoButton1" @click="isMorePhoto = true; MorePhotoButton1 = false;
            MorePhotoButton2 = true">
                Больше фото
            </div>

            <div class="button" v-if="MorePhotoButton2" @click="isMoreMorePhoto = true; MorePhotoButton2 = false">
                Больше фото
            </div>
        </div>
    </div>
</template>

<script>
import ModalWindow from './ModalWindow.vue';

export default {
    name: 'ExamplesPart',
    data() {
        return {
            isModalOpen: false,
            isServices: false,
            isPhoto: false,
            isLicense: false,
            id: 0,
            isMorePhoto: false,
            MorePhotoButton1: true,
            MorePhotoButton2: false,
            isMoreMorePhoto: false,
        };
    },
    components: { ModalWindow },
    methods: {
        showPhoto(id) {
            this.isModalOpen = true;
            this.isPhoto = true;
            this.id = id;
        }
    }
}
</script>

<style lang="sass">
.photo
    border-radius: 10px
    height: 340px
    cursor: pointer
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
    &:active,&:focus 
        transform: scale(0.9)
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1)

.examples-buttons
    display: flex
    align-items: center
    justify-content: center
    column-gap: 64px
    padding: 0 16px 16px 16px
    text-align: center

.button
    font-family: 'Noto Sans', sans-serif
    background-color: #fdc500
    font-size: 16px
    border: 0
    padding: 16px 24px
    border-radius: 30px
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
    &:active,&:focus 
        transform: scale(0.9)
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1)
</style>
