<template>
    <div class="page fade-in">
        <AppContent></AppContent>
    </div>
</template>

<script>
import AppContent from '@/components/AppContent.vue';

export default {
  name: 'App',
  components: {
    AppContent
  }
}
</script>

<style lang="sass">
#app
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: #2c3e50

body
    margin: 0

body
    background-color: #E5E5EA
    margin: 0
    overflow: overlay
    min-width: 376px
    max-width: 2560px
    margin-left: auto
    margin-right: auto
    background-attachment: scroll

body::-webkit-scrollbar
    width: 16px

body::-webkit-scrollbar-track
    background: #E5E5EA

body::-webkit-scrollbar-thumb
    background-color: rgb(0,0,0, 0.3)
    border-radius: 16px
    border: 4px solid #E5E5EA

// body::-webkit-scrollbar-button 
//     display:none

html
    scroll-behavior: smooth

.page
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2)
    box-shadow: 0px 5px 50px 2px rgba(34, 60, 80, 0.2)

.fade-in
    opacity: 0
    animation: fade-in 0.5s ease-in forwards

@keyframes fade-in
    from
        opacity: 0
    to
        opacity: 1
</style>