<template>
    <div class="contact" id="contact">
        <div class="title">
            <h2>Контакты</h2>
        </div>

        <div class="contact-card">
            <div class="contact-upper-part">
                <p>+7 910 970-43-77</p>

                <p>Игорь Евгеньевич К.</p>

                <p>
                    Пн - Сб
                    <br>
                    с 9:00 до 20:00
                </p>
            </div>

            <div class="contact-bottom-part">
                <div style="background-color: #fdc500" class="a-hide-full-buttons contact-buttons copy-button"
                data-clipboard-text="+79109704377">
                    <img src="../../assets/img/buttons/copy_1.svg" alt="" class="contact-icon">

                    <p style="color: #1C1C1E">Cкопировать номер телефона</p>
                </div>

                <a href="https://wa.me/79109704377" class="a-hide-full-buttons">
                    <div style="background-color: #2BBF1A"  class="contact-buttons">
                        <img src="../../assets/img/buttons/whatsapp.svg" alt="" class="contact-icon">
        
                        <p>Написать в WhatsApp</p>
                    </div>
                </a>

                <a href="tel:79109704377" class="a-hide-full-buttons">
                    <div style="background-color: #2196F3" class="contact-buttons">
                        <img src="../../assets/img/buttons/telephone.svg" alt=""  class="contact-icon">
        
                        <p>Позвонить по номеру телефона</p>
                    </div>
                </a>

                <img src="../../assets/img/buttons/copy_2.svg" alt="" class="mini-buttons copy-button"
                data-clipboard-text="+79109704377">

                <a href="https://wa.me/79109704377" class="a-button"><img src="../../assets/img/buttons/whatsapp.svg" alt=""
                    class="mini-buttons"></a>

                <a href="tel:79109704377" class="a-button"><img src="../../assets/img/buttons/telephone.svg" alt="" class="mini-buttons"></a>
            </div>
        </div>
    </div>
</template>

<script>
import ClipboardJS from "clipboard"

export default {
    name: "ContactPart",
    mounted() {
        const clipboard = new ClipboardJS('.copy-button');
        clipboard.on('success', (e) => {
            console.log('Текст скопирован: ', e.text);
            // Дополнительные действия при успешном копировании
        });
    }
}
</script>
  
<style lang="sass">
.contact
    background-color: #F2F2F7
    padding-bottom: 32px

.p-card
    font-size: 20px
    text-align: left
    color: #1C1C1E
    
.contact-card
    background-color: rgb(0 0 0 / 5%)
    margin-left: auto
    margin-right: auto
    border-radius: 10px
    padding: 16px
    width: 1000px
    margin-top: 48px
    background-color: rgba(255, 255, 255, 0.75)
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
    &:focus 
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1)

.contact-upper-part
    display: flex
    align-items: center
    justify-content: space-evenly
    column-gap: 32px
    row-gap: 32px

.contact-bottom-part
    display: flex
    margin-top: 32px
    column-gap: 16px
    justify-content: space-evenly
    flex-wrap: wrap
    row-gap: 24px

.contact-buttons
    display: flex
    align-items: center
    column-gap: 1rem
    border-radius: 10px
    padding-right: 15px
    padding-left: 5px
    height: 32px
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
    &:active,&:focus 
        transform: scale(0.9)
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1)

.contact-buttons p
    color: #F2F2F7

.contact-icon-buttons
    height: 32px
    display: none
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
    &:active,&:focus 
        transform: scale(0.9)
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1)

.contact-icon
    width: 32px

.a-button
    display: none

.mini-buttons
    width: 32px
    display: none
    transition: transform 0.2s ease-in-out
    &:hover 
        transform: scale(1.1)
    &:active,&:focus 
        transform: scale(0.9)
        transition: transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1)
    display: none
</style>
